document.addEventListener("DOMContentLoaded", function () {
  runDropdownActions();
  runContactActions();
});

function runDropdownActions() {
  // Get all the buttons and their corresponding containers
  const readMoreButtons = document.querySelectorAll(".read-more-button");
  const contentContainers = document.querySelectorAll(".read-more-content");

  // Loop through each button to add click event listener
  readMoreButtons.forEach((button, index) => {
    button.addEventListener("click", function () {
      // Toggle the height of the content container associated with the clicked button
      const contentContainer = contentContainers[index];
      toggleHeight(contentContainer);

      // Toggle the text of the button
      button.textContent =
        contentContainer.style.height === "fit-content"
          ? "Read Less"
          : "Read More";
    });
  });

  // Function to toggle the height of the content container
  function toggleHeight(container) {
    // Check if the content container height is currently set to fit-content
    if (container.style.height === "fit-content") {
      // If the height is already set to fit-content, set it back to the specific value
      container.style.height = "51px"; // Replace '51px' with the desired specific height when collapsed
    } else {
      // If the height is not set to fit-content, set it to fit-content
      container.style.height = "fit-content";
    }
  }
}

function runContactActions() {
  // Constant for the API endpoint
  const API_HOST = "https://api.omnione.health";

  // Get the form element and submit button
  const contactForm = document.getElementById("contact-form");
  const submitButton = document.getElementById("submit-button");

  // Add submit event listener to the form
  contactForm.addEventListener("submit", function (event) {
    event.preventDefault();

    // Disable the submit button and show loading indicator
    submitButton.disabled = true;
    submitButton.textContent = "Sending...";

    // Get the values from the input fields
    const organizationName = document.getElementById(
      "contact-organization-name"
    ).value;
    const phoneNumber = document.getElementById(
      "contact-organization-phone-number"
    ).value;
    const email = document.getElementById("contact-organization-email").value;
    const message = document.getElementById(
      "contact-organization-message"
    ).value;

    // Assemble the data into a JSON object
    const requestData = {
      sender: email,
      to: "info@omnione.health",
      message: message,
      phoneNumber: phoneNumber,
      name: organizationName,
      subject: "Contact Us Inquiry",
    };

    // Send the data via an API call
    sendContactUsRequest(requestData);
  });

  // Function to send the contact us request
  function sendContactUsRequest(data) {
    fetch(`${API_HOST}/api/v1/notification/send-contactus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log("Request:", JSON.stringify(data));
        console.log("Response:", JSON.stringify(response)); // Log response as stringified JSON
        return response.json(); // Parse response body as JSON
      })
      .then((responseData) => {
        console.log("Response Data:", JSON.stringify(responseData)); // Log response data as stringified JSON
        // Enable the submit button and show success message
        submitButton.disabled = false;
        submitButton.textContent = "Submit";
        contactForm.reset();
        alert(
          "Thank you for contacting us, we'll get back to you as soon as possible."
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to send contact us message. Please try again later.");
        // Re-enable the submit button in case of error
        submitButton.disabled = false;
        submitButton.textContent = "Submit";
      });
  }
}
